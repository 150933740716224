<template>
  <div class="ui-select" :class="{ 'ui-select_opened': isSelectOpen }">
    <p class="ui-select__current" @click="isSelectOpen = !isSelectOpen">
      {{ selectedOption[titleAttrName] }}
    </p>

    <ul class="ui-select__items ui-select__items_position_bottom">
      <li class="ui-select__item" v-for="option in options" :key="option[valueAttrName]"
        :class="{ 'ui-select__item_current': option[valueAttrName] === modelValue }"
        @click.prevent="selectOption(option)">
        {{ option[titleAttrName] }}
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: 'Select',
  props: {
    options: {
      type: Array,
      required: true
    },
    titleAttrName: {
      type: String,
      required: true
    },
    valueAttrName: {
      type: String,
      required: true
    },
    modelValue: {
      type: String,
      required: true
    }
  },
  model: {
    prop: 'modelValue',
    event: 'change'
  },
  data: () => ({
    isSelectOpen: false
  }),
  computed: {
    selectedOption() {
      return this.options.find(option => option[this.valueAttrName] === this.modelValue);
    }
  },
  methods: {
    selectOption(option) {
      this.$emit('change', option[this.valueAttrName])
      this.isSelectOpen = false
    }
  }
}
</script>
<style scoped>
.ui-select {
  position: relative;
  /* z-index: 1; */
  cursor: pointer;
}

.ui-select_opened .ui-select__current:after {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.ui-select_opened .ui-select__items {
  display: block;
}

.ui-select__current {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: space-between;
  padding: 12px 20px 12px 24px;
  background: rgba(126, 206, 202, 0.1);
  border-radius: 100px;
  text-align: center;
  -webkit-column-gap: 10px;
  -moz-column-gap: 10px;
  column-gap: 10px;
  font-size: 16px;
}

@media (min-width: 768px) {
  .ui-select__current {
    padding: 12px 14px 12px 19px;
  }
}

.ui-select__current:after {
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='8' viewBox='0 0 12 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.00586 7.13867C5.72461 7.13867 5.4668 7.02344 5.23242 6.79297L0.896484 2.35742C0.810547 2.26758 0.744141 2.16992 0.697266 2.06445C0.654297 1.95508 0.632812 1.83789 0.632812 1.71289C0.632812 1.53711 0.673828 1.37891 0.755859 1.23828C0.841797 1.09375 0.955078 0.980469 1.0957 0.898438C1.23633 0.8125 1.39258 0.769531 1.56445 0.769531C1.82617 0.769531 2.05664 0.869141 2.25586 1.06836L6.24609 5.17578H5.77148L9.75 1.06836C9.94922 0.869141 10.1777 0.769531 10.4355 0.769531C10.6074 0.769531 10.7637 0.8125 10.9043 0.898438C11.0449 0.980469 11.1562 1.09375 11.2383 1.23828C11.3242 1.37891 11.3672 1.53711 11.3672 1.71289C11.3672 1.9668 11.2793 2.18164 11.1035 2.35742L6.77344 6.79297C6.65625 6.91016 6.53516 6.99609 6.41016 7.05078C6.28516 7.10547 6.15039 7.13477 6.00586 7.13867Z' fill='%23A2DCD9' fill-opacity='0.6'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 12px;
  height: 12px;
  display: block;
  position: relative;
  margin: auto 0;
  -webkit-transition: 0.3s ease;
  -o-transition: 0.3s ease;
  transition: 0.3s ease;
}

.ui-select__items {
  display: none;
  position: absolute;
  background: #132323;
  padding: 8px 0;
  border: 0.5px solid rgba(126, 206, 202, 0.1);
  border-radius: 20px;
  width: 160px;
  z-index: 1;
  width: 100%;

  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
}

.ui-select__items_position_up {
  bottom: calc(100% + 8px);
}

.ui-select__items_position_bottom {
  top: calc(100% + 8px);
}

.ui-select__item {
  display: block;
  padding: 10px 20px;
  position: relative;
  font-size: 16px;
  text-align: start;
}

.ui-select__item_current:before {
  content: "";
  display: block;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml,%3Csvg width='14' height='13' viewBox='0 0 14 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.5918 12.21C5.22721 12.21 4.91732 12.0527 4.66211 11.7383L1.12109 7.37695C1.02083 7.25846 0.947917 7.14225 0.902344 7.02832C0.861328 6.91439 0.84082 6.7959 0.84082 6.67285C0.84082 6.39941 0.931966 6.17383 1.11426 5.99609C1.29655 5.81836 1.52669 5.72949 1.80469 5.72949C2.11914 5.72949 2.38346 5.86393 2.59766 6.13281L5.56445 9.89258L11.3545 0.71875C11.473 0.536458 11.596 0.408854 11.7236 0.335938C11.8512 0.258464 12.0107 0.219727 12.2021 0.219727C12.4801 0.219727 12.708 0.306315 12.8857 0.479492C13.0635 0.652669 13.1523 0.873698 13.1523 1.14258C13.1523 1.25195 13.1341 1.36133 13.0977 1.4707C13.0612 1.58008 13.0042 1.69401 12.9268 1.8125L6.52832 11.7109C6.30957 12.0436 5.9974 12.21 5.5918 12.21Z' fill='%2300D5C8'/%3E%3C/svg%3E%0A");
  width: 20px;
  height: 20px;
  position: absolute;
  right: 10px;
  top: 0;
  bottom: 0;
  margin: auto 0;
}
</style>