<template>
    <div>
        <LndHeader @openPopupLogin="showPopupLogin = true" @openMobileMenu="showMobileMenu = true" />

        <main class="main-page">
            <Manuals />
        </main>

        <LndFooter />

        <LndPopupCheckout
            v-if="isPopupCheckoutActive"
            @close="closePopupCheckout"
        />
        <LndPopupLogin
            v-if="showPopupLogin"
            @close="showPopupLogin = false"
            @showPopupResetPassword="showReset"
        />

        <LndPopupResetPassword
            v-if="showPopupResetPassword"
            @close="showPopupResetPassword = false"
        />

        <LndScripts />
    </div>
</template>

<script>
import LndHeader from '@/components/lnd/Header.vue'
import LndFooter from '@/components/lnd/Footer.vue'
import LndPopupCheckout from '@/components/lnd/PopupCheckout.vue'
import LndPopupLogin from '@/components/lnd/PopupLogin.vue'
import LndScripts from '@/components/lnd/Scripts.vue'
import LndPopupResetPassword from '@/components/lnd/PopupResetPassword.vue'
import Manuals from '@/components/Manuals.vue'

export default {
    components: {
        LndHeader,
        LndFooter,
        LndPopupCheckout,
        LndPopupLogin,
        LndScripts,
        LndPopupResetPassword,
        Manuals,
    },
    data() {
        return {
            showPopupLogin: false,
            showPopupResetPassword: false,
            showMobileMenu: false,
            isPopupCheckoutActive: false,
            fixed: true
        }
    },
    methods: {
        showMobileLogin() {
            console.debug('%c showMobileLogin', 'color:lime;font-size:24px;')
            this.showMobileMenu = false
            this.showPopupLogin = true
        },
        showReset() {
            this.showPopupLogin = false
            this.showPopupResetPassword = true
        },
        openPopupCheckout() {
            this.isPopupCheckoutActive = true
        },
        closePopupCheckout() {
            this.isPopupCheckoutActive = false
        }
    }
}
</script>

<style>
.fixed {
    box-shadow: 0 0 0 5px lime inset;
    overflow: hidden;
    max-height: 100vh;
}
</style>